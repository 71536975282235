import styles from "./ITUCert.module.css"
import { useEffect, useState } from "react";
import SingleGallery from './Gallery/SingleGallery'
import PhotoSwipeLightbox from "photoswipe/lightbox";
import 'photoswipe/style.css';

function ITUCert() {
  let [certs] = useState([
    {
      "url": "img/itu-cert/ISO-45001-2018-Certificate-Winblock.jpg",
      "thumb": "img/itu-cert/ISO-45001-2018-Certificate-Winblock.jpg",
      "width": 1024,
      "height": 1446,
    },
    {
      "url": "img/itu-cert/ISO-14001-2015-Certificate-Winblock.jpg",
      "thumb": "img/itu-cert/ISO-14001-2015-Certificate-Winblock.jpg",
      "width": 1024,
      "height": 1446
    },
    {
      "url": "img/itu-cert/ISO-10002-2018-Certificate-Winblock.jpg",
      "thumb": "img/itu-cert/ISO-10002-2018-Certificate-Winblock.jpg",
      "width": 1024,
      "height": 1446
    },
    {
      "url": "img/itu-cert/ISO-9001-2015-Certificate-Winblock.jpg",
      "thumb": "img/itu-cert/ISO-9001-2015-Certificate-Winblock.jpg",
      "width": 1024,
      "height": 1446
    }
  ]);

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: ".psg",
      children: "a",
      pswpModule: () => import("photoswipe"),
      showHideAnimationType: 'fade',
      bgOpacity: 0.75,
      wheelToZoom: true
    });
    lightbox.init();
    return () => {
      lightbox.destroy();
      lightbox = null;
    }
  }, []);

  return (
    <section>
      <div className="container" >
        <div className="row">
          <div className="text-center col-12 pb-3">
            <h1>Sertifikati</h1>
            <div className="col-12 mt-4">
              <SingleGallery images={certs} />
            </div>
            <div className="col-12 pb-3">
              <div className="row justify-content-center text-center">
                <p className="col-sm-2">ISO-45001-2018</p>
                <p className="col-sm-2">ISO-14001-2015</p>
                <p className="col-sm-2">ISO-10002-2018</p>
                <p className="col-sm-2">ISO-9001-2015</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="" className={styles.ituCertBck + ` d-flex align-items-center`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/img/itu-cert/cover8.jpg'})` }}>
        <div className="container">
          <div className="row">
            <div className="col-10 mx-auto text-center">
              <h2>Testiran u laboratorijama <br />Istanbulskog tehničkog univerziteta</h2>
              <p className={styles.yellowDesc}>
                Patent broj 2018/06277 - Prijava za registraciju dizajna broj 2018/03647
              </p>
              <p>
                <img src={`${process.env.PUBLIC_URL}/img/itu-cert/itu-test.png`} width="950" height="180" className="img-fluid" alt="Testiranje" />
              </p>
              <p className="mt-5">
                <a href={`${process.env.PUBLIC_URL}/doc/WinBlock-ITU-Technical-Report-EN.pdf`} target="_blank" rel="noreferrer" className={styles.itucertlink}>
                  <img src={`${process.env.PUBLIC_URL}/img/common/icon-pdf.png`} width="80" height="80" className="img-fluid" alt="PDF ikonica" loading="lazy" /> Izveštaj ITU na engleskom
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ITUCert;
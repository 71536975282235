import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HumanGrid from "./components/HumanGrid";
import AnimalGrid from './components/AnimalGrid'
import NotFound from './components/NotFound'

function App() {
  return (
    <div id="pocetna">

      <BrowserRouter>
        <Routes>
          <Route index element={<HumanGrid />} />
          <Route path="zivotinje" element={<AnimalGrid />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react'
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Slider from "./Content/Slider";
import FeaturesShort from "./Content/FeaturesShort";
import InstallationVideo from "./Content/InstallationVideo";
import Features from "./Content/Features";
import ITUCert from "./Content/ITUCert";
import Montaza from "./Content/Montaza";
import Gallery from "./Content/Gallery/Gallery";
import Contact from "./Content/Contact";
function HumanGrid() {
  return (
    <div id="pocetna">
      <Header />
      <Slider />
      <FeaturesShort />
      <InstallationVideo />
      <Features />
      <ITUCert />
      <Montaza />
      <Gallery />
      <Contact />
      <Footer />
    </div>
  )
}

export default HumanGrid
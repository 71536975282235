import React from 'react'
import Contact from './Content/Contact'
import Footer from './Footer/Footer'
import Header from './Header/Header'

function NotFound() {
  return (
    <div>
      <Header />
      <div className="row m-4">
        <div className="col-12 text-center">
          <h1>Nepostojeća strana</h1>
          <p>Strana koju pokušavate da otvorite ne postoji</p>
        </div>
      </div>
      <Contact />
      <Footer />
    </div>
  )
}

export default NotFound
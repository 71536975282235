import React from 'react'
import Header from './Header/Header'
import Contact from './Content/Contact'
import Footer from './Footer/Footer'
import Slider from './Content/Slider'
function AnimalGrid() {
  return (
    <>
      <Header />
      <Slider />
      <Contact />
      <Footer />
    </>
  )
}

export default AnimalGrid
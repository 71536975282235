import { useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import 'photoswipe/style.css';


function SingleGallery(props) {
  useEffect(() => {
    // console.log(props);
    // console.log("Kreirana komponenta Single Gallery");
    let lightbox = new PhotoSwipeLightbox({
      gallery: ".psg",
      children: "a",
      pswpModule: () => import("photoswipe"),
      showHideAnimationType: 'fade',
      bgOpacity: 0.75,
      wheelToZoom: true

    });
    lightbox.init();
    return () => {
      lightbox.destroy();
      lightbox = null;
    }

  }, []);

  return (
    <div className="row psg justify-content-center text-center">
      {props.images && props.images.map((img, imgKey) => (
        <div className="col-sm-2">
          <a
            key={imgKey}
            href={img.url}
            data-pswp-width={img.width}
            data-pswp-height={img.height}
            title="Slika galerije"
            target="_blank"
            rel="noreferrer"
            className="p-2"
            data-cropped="true"
          >
            <img src={img.thumb} alt="." className="img-fluid mb-3" />
          </a>
        </div>
      ))}
    </div>
  )

}
export default SingleGallery;
import styles from "./TopNavBar.module.css";
import {
  TelephoneFill,
  EnvelopeFill,
  Facebook,
  Instagram,
} from "react-bootstrap-icons";

function TopNavBar() {
  return (
    <div className={styles.black + " d-none d-md-block"}>
      <div className="container py-2">
        <div className="d-flex justify-content-between">
          <div>
            <span>WinBlock® čelični sigurnosni mrežni sistem</span>
          </div>
          <div>
            <span className="me-3">
              <a href="https://www.facebook.com/zastitnamreza" target="_blank" rel="noreferrer" aria-label="Facebook strana Winblock Srbija">
                <Facebook />
              </a>
            </span>
            <span className="me-3">
              <a href="https://www.instagram.com/winblocksrbija/" rel="noreferrer" target="_blank" aria-label="Instagram strana Winblock Srbija">
                <Instagram />
              </a>
            </span>
            <span className="me-3">
              <TelephoneFill />{" "}<a className="ps-1" href="tel:+381600900606" aria-label="Telefon za poziv">060 090 06 06</a>{" "}
              <TelephoneFill className="ms-3" />{" "}<a className="ps-1" href="tel:+381652982991 " aria-label="Telefon za poziv">065 29 82 991</a>
            </span>
            <span>
              <EnvelopeFill />
              <a href="mailto:kontakt@winblock.rs?Subject=[Kontakt%20sa%20sajta]" className="ms-1" aria-label="Mejl za kontakt winblock srbija">
                kontakt@winblock.rs
              </a> |
              <a href="mailto:info@winblock.rs?Subject=[Kontakt%20sa%20sajta]" className="ms-1" aria-label="Mejl za kontakt winblock srbija">
                info@winblock.rs
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TopNavBar;
